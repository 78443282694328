var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-template-content col-8"},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('CREATE_OPTION_PAGE_LABEL_TITLE')))])]),_c('CCardBody',[_c('ValidationObserver',{ref:"emailTemplate"},[_c('CForm',[_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"CREATE_OPTION_PAGE_FIELD_OPTION_NAME","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('CREATE_OPTION_PAGE_LABEL_OPTION_NAME')},model:{value:(_vm.option_name),callback:function ($$v) {_vm.option_name=$$v},expression:"option_name"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"CREATE_OPTION_PAGE_FIELD_DISPLAY_ORDER","rules":"min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('CREATE_OPTION_PAGE_LABEL_DISPLAY_ORDER')},model:{value:(_vm.display_order),callback:function ($$v) {_vm.display_order=$$v},expression:"display_order"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('CTabs',{staticClass:"mt-3",attrs:{"variant":"tabs","active-tab":0}},_vm._l((_vm.group_language),function(item,index){return _c('CTab',{key:index,attrs:{"title":_vm.$t(("EDIT_EMAIL_TEMPLATE_PAGE_TAB_" + (_vm.upperCase(item)) + "_LABEL_TITLE"))}},[_c('ValidationObserver',{on:{"change":function($event){return _vm.onFieldChange(item)}}},[_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":("CREATE_OPTION_PAGE_FIELD_SUBJECT_" + (_vm.upperCase(item))),"rules":(item=='en' || _vm.isJaFieldChange) ? 'required|max:1000' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('CREATE_OPTION_PAGE_LABEL_SUBJECT')},model:{value:(_vm.email_templates[item].subject),callback:function ($$v) {_vm.$set(_vm.email_templates[item], "subject", $$v)},expression:"email_templates[item].subject"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":("CREATE_OPTION_PAGE_FIELD_TEMPLATE_" + (_vm.upperCase(item))),"rules":(item=='en' || _vm.isJaFieldChange) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('CREATE_OPTION_PAGE_LABEL_TEMPLATE')))]),_c('yimo-vue-editor',{model:{value:(_vm.email_templates[item].template),callback:function ($$v) {_vm.$set(_vm.email_templates[item], "template", $$v)},expression:"email_templates[item].template"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1)],1)}),1),_c('CRow',[_c('CCol',[_c('CButton',{staticClass:"btn-save",on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_BUTTON')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }