<template>
  <div class="email-template-content col-8">
    <CCard>
      <CCardHeader>
        <h1>{{$t('CREATE_OPTION_PAGE_LABEL_TITLE')}}</h1>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver ref="emailTemplate">
          <CForm>
            <CRow>
              <CCol>
                <ValidationProvider
                  name="CREATE_OPTION_PAGE_FIELD_OPTION_NAME"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <CInput
                    :label="$t('CREATE_OPTION_PAGE_LABEL_OPTION_NAME')"
                    v-model="option_name"
                  />
                  <span class="error-msg-wrap">
                    <span class="error-msg">{{errors[0]}}</span>
                  </span>
                </ValidationProvider>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <ValidationProvider
                  name="CREATE_OPTION_PAGE_FIELD_DISPLAY_ORDER"
                  v-slot="{ errors }"
                  rules="min_value:1"
                >
                  <CInput
                    :label="$t('CREATE_OPTION_PAGE_LABEL_DISPLAY_ORDER')"
                    v-model="display_order"
                  />
                  <span class="error-msg-wrap">
                    <span class="error-msg">{{errors[0]}}</span>
                  </span>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CTabs variant="tabs" class="mt-3" :active-tab="0">
              <CTab
                v-for="(item, index) in group_language"
                :key="index"
                :title="$t(`EDIT_EMAIL_TEMPLATE_PAGE_TAB_${upperCase(item)}_LABEL_TITLE`)"
              >
                <ValidationObserver @change="onFieldChange(item)">
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        :name="`CREATE_OPTION_PAGE_FIELD_SUBJECT_${upperCase(item)}`"
                        v-slot="{ errors }"
                        :rules="(item=='en' || isJaFieldChange) ? 'required|max:1000' : ''"
                      >
                        <CInput
                          :label="$t('CREATE_OPTION_PAGE_LABEL_SUBJECT')"
                          v-model="email_templates[item].subject"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        :name="`CREATE_OPTION_PAGE_FIELD_TEMPLATE_${upperCase(item)}`"
                        v-slot="{ errors }"
                        :rules="(item=='en' || isJaFieldChange) ? 'required' : ''"
                      >
                        <label class>{{$t('CREATE_OPTION_PAGE_LABEL_TEMPLATE')}}</label>
                        <yimo-vue-editor v-model="email_templates[item].template"></yimo-vue-editor>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </ValidationObserver>
              </CTab>
            </CTabs>
            <CRow>
              <CCol>
                <CButton
                  class="btn-save"
                  @click="onSubmit"
                >{{$t('EDIT_EMAIL_TEMPLATE_PAGE_BUTTON')}}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { Constants, env } from '@/constants';
import YimoVueEditor from 'yimo-vue-editor';

export default {
  name: 'AddEmailTemplateOption',
  components: {
    YimoVueEditor,
  },
  data() {
    return {
      group_language: env.emailLanguages,
      email_templates: this.setEmailTemplateDefault(),
      group_name: '',
      display_order: null,
      option_name: '',
      isJaFieldChange: false,
      name: '',
      isEdit: false,
    };
  },
  mounted() {
    const name = this.$route.params.name;
    if (name) {
      this.name = name;
      this.getEmailOptionDetail();
      this.isEdit = true;
    } else {
      this.group_name = this.$route.params.groupName;
    }
  },
  methods: {
    setEmailTemplateDefault() {
      const emaiTemplate = {};
      env.emailLanguages.forEach(item => {
        emaiTemplate[item] = {
          subject: '',
          template: '',
          language: item,
        };
      });
      return emaiTemplate;
    },
    async getEmailOptionDetail() {
      try {
        const res = await this.$http.get(endpoints.getEmailDetail(this.name));
        if (res.data) {
          const items = res.data;
          const defaultEmailTemplate = { ...this.email_templates };

          this.option_name = items[0].option_name || '';
          this.display_order = items[0].display_order || null;

          items.forEach(e => {
            defaultEmailTemplate[e.language] = {
              subject: e.subject,
              template: e.template,
              language: e.language,
            };
          });
          this.email_templates = defaultEmailTemplate;
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CREATE_OPTION_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.emailTemplate.validate();
      if (!isValid) return;

      try {
        const items = [];
        this.group_language.forEach(e => {
          if (this.email_templates[e].subject != '') {
            items.push(this.email_templates[e]);
          }
        });

        let data = {};
        if (!this.isEdit) {
          data = {
            option_name: this.option_name,
            group_name: this.group_name,
            display_order: this.display_order || null,
            email_templates: items,
          };
          await this.$http.post(endpoints.createOptionsTemplate, data);
        } else {
          data = {
            option_name: this.option_name,
            display_order: this.display_order || null,
            email_templates: items,
          };
          await this.$http.put(endpoints.updateEmailTempalteOption(this.name), data);
        }
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('CREATE_OPTION_PAGE_NOTIFY_TITLE_SUCCESSFUL'),
          text: !this.isEdit ? this.$t('CREATE_OPTION_PAGE_NOTIFY_TEXT_SUCCESSFUL') : this.$t('EDIT_OPTION_PAGE_NOTIFY_TEXT_SUCCESSFUL'),
        });
        this.$router.push('/admin/setting/email-templates');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CREATE_OPTION_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    upperCase(val) {
      return _.upperCase(val);
    },
    onFieldChange(el) {
      if (el == 'ja') {
        this.isJaFieldChange = true;
      }
    },
  },
};
</script>
<style lang="scss">
.email-template-content {
  padding: 0 15px;
  .form-group {
    margin-bottom: 0;
  }
  h1 {
    color: #657187;
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .tab-content {
    padding: 30px 10px 0 10px;
  }
  .btn-save {
    background: #3b4b7e;
    color: #fff;
    padding: 5px 40px;
    margin-top: 30px;
  }
  textarea {
    &.form-control {
      height: 400px;
    }
  }
}
</style>
